@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Roboto&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
  }
  
  :root{
    --mainColor: #e2e5ea;
    --mainColorLight: #5e6d7d;
    --secondaryColor: #E3E3E3; 
    --whiteColor: #FFFFFF;
    --darkText: #454749; 
    --blueText: #2b5a8d;
    --readable: #EDEADE;
  }
/* Home */

#portrait{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    margin: 100px 50px 150px 50px;
    font-family: 'Roboto', sans-serif;
  }

#divPortrait{
  height: 400px;
  width: 700px;
  opacity: 1;
  background-image: url('../images/portrait.jpg');
  background-repeat: no-repeat;
  background-position: center top -340px;
  animation-name: fadingPortrait;
  animation-duration: 4s;
}

@keyframes fadingPortrait{
  from{opacity: 0;}
  to{opacity: 1;}
}
  
  #homeText div p{
    color: var(--readable);
    font-size: 24px;
    margin-left: 50px;
    font-family: 'Roboto', sans-serif;
  }

  #square{
    width: 20px;
    height: 20px;
    background-color: var(--readable);
    margin-left: 50px;
    transition: transform 1s;
  }

  #square:hover{
    background-color: var(--blueText);
    transform: rotate(-40deg);
  }

  /* media query screen HOME */
@media only screen and (max-width: 768px){

  #portrait{
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
  } 

  #divPortrait{
    height: 100vh;
    width: 100%;
    opacity: 1;
    background-image: url('../images/portrait.jpg');
    background-repeat: no-repeat;
    background-position: center top -340px;
    animation-name: fadingPortrait;
    animation-duration: 4s;
    margin-bottom: -10px;
  }

  #homeText{
    width: 100%;
    margin-top: 5px;
  }
  
}