@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root{
  --mainColor: #e2e5ea;
  --mainColorLight: #5e6d7d;
  --secondaryColor: #E3E3E3; 
  --whiteColor: #FFFFFF;
  --lightGrey: #e6e7e7;
  --darkText: #454749; 
  --darkMode: #0A0A0A;
  --darkShade: #181818;
  --blueText: #2b5a8d;
}

body{
  background: linear-gradient(to bottom, var(--darkMode), var(--darkMode), var(--darkText));
  font-family: 'Roboto', sans-serif;
}