@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
  }
  
  :root{
    --mainColor: #e2e5ea;
    --mainColorLight: #5e6d7d;
    --secondaryColor: #E3E3E3; 
    --whiteColor: #FFFFFF;
    --lightGrey: #e6e7e7;
    --darkText: #454749;
    --darkMode: #0A0A0A;
    --darkShade: #181818;
    --blueText: #2b5a8d;
    --readable: #EDEADE;
  }

#musicDiv{
    margin: 100px 50px 150px 50px;
    background: radial-gradient(black, black, var(--darkMode), var(--darkMode));
}

#musicDiv #music{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 50px 100px 50px;
}

#musicDiv #music a{
  text-decoration: underline;
  color: var(--readable);
  transition: 1s;
}

#musicDiv #music a:hover{
  transform: scale(0.5);
}

#musicDiv #music span{
  color: var(--readable);
  margin: -10px 10px 0 0;
}

.music{
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 50px solid var(--readable);
  border-bottom: 25px solid transparent;
}

@media only screen and (max-width: 768px){
  #musicDiv{
    margin: 50px 0px 50px 0px;
    background: radial-gradient(black, black, var(--darkMode), var(--darkMode));
    width: 100%;
}

  #music span img{
    width: 200px !important;
  }
}