@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
  }
  
  :root{
    --mainColor: #e2e5ea;
    --mainColorLight: #5e6d7d;
    --secondaryColor: #E3E3E3; 
    --whiteColor: #FFFFFF;
    --offWhite: #F9F6EE;
    --lightGrey: #e6e7e7;
    --darkText: #454749;
    --darkMode: #0A0A0A;
    --darkShade: #181818;
    --darkShadeTwo: #121212;
    --blueText: #2b5a8d;
    --readable: #EDEADE;
  }

  .essaysTitle{
    margin: 0 50px 0 50px;
}

#divEssays{
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(50deg, var(--darkShade), var(--darkShadeTwo));
    margin: 50px 50px 150px 50px;
    padding: 40px;
}

#essaysBorder{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
/* Frames on Grid */
.essaysText{
    box-shadow: 3px 3px var(--darkShade), -5px 0 2px black;
    padding: 20px;
    margin: 20px;
    width: 80%;
    color: var(--readable);
    font-size: 24px;
    transition: 1s;
    display: flex;
    flex-direction: column;
    
}

.essaysText:hover{
    box-shadow: 5px 5px var(--darkShade), -3px 0 5px var(--darkText);
    transform: scale(1.1) translateY(-10px);
}

.essaysText a{
    text-decoration: none;
}

.essaysText span{
    display: flex;
    justify-content: end;
    align-items: flex-end;
    font-size: 20px;
    color: var(--readable);
}

.essaysLink{
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: 1s ease-in-out;
}

.essays-one{
    display: flex;
    justify-content: start;
    line-height: 50px;
}

.essays-one a:hover{
    text-decoration: underline;
    color: var(--readable);
}

@media only screen and (max-width: 768px){
    #divEssays{
        margin: 50px 0px 50px 0px;
    }

    .essaysText{
        width: 100%;
        padding: 50px 5px 50px 5px;
        display: flex;
        align-items: center;
    }

    .essaysLink div span{
        display: flex;
        justify-content: end;
        align-items: flex-end;
    }
}