@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('http://www.w3.org/2000/svg');



*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
  }
  
  :root{
    --mainColor: #e2e5ea;
    --mainColorLight: #5e6d7d;
    --secondaryColor: #E3E3E3; 
    --whiteColor: #FFFFFF;
    --offWhite: #F9F6EE;
    --lightGrey: #e6e7e7;
    --darkText: #454749;
    --darkMode: #0A0A0A;
    --darkShade: #181818;
    --darkShadeTwo: #121212;
    --blueText: #2b5a8d;
    --readable: #EDEADE;
  }
.aBook{
    color: inherit;
    text-decoration: none;
}
.aBook:hover{
    color: var(--mainColorLight);
    text-decoration: underline;
}
/* Books page */
#bookDivW{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 100px 50px 150px 50px;
}

.booksImagesW{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
}

.firstBookImg{
    width: 280px;
}

legend{
    font-size: 24px;
    color: var(--readable);
    text-decoration: underline;
    text-align: center;
}

.divBuyBookBtn{
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
}
/* button to buy book */
.buyBookBtn{
    border: 1px solid var(--darkText);
    text-align: center;
    padding: 15px;
    margin-left: 20px;
    background-color: var(--darkText);
    color: var(--readable);
    font-weight: bold;
    border-radius: 2%;
    text-decoration: none;
}

.buyBookBtn:hover{
    background-color: var(--blueText);
    color: var(--whiteColor);
}

.allBooksWriting{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.allBooksWriting .imageAndLegend{
    display: flex;
    flex-direction: column;
}

.firstBookImg{
    opacity: 1;
    animation-name: fadingBook;
    animation-duration: 4s;
}

@keyframes fadingBook {
    from {opacity: 0;}
    to {opacity: 1;}
}

#see-review{
    margin: 100px;
}

.author-reviews{
    text-decoration: underline;
}

.text-review{
    background-color: var(--darkShade) !important;
    margin: 10px 0 10px 0;
}
/* design font awesome icon on accordion */

/* svg icon */
.accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='%23edeade'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M4 8L12 16L20 8' stroke='%23edeade' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}
  
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='%23edeade' transform='rotate(180)'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M20 16L12 8L4 16' stroke='%23edeade' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.see-review button{
    background-color: linear-gradient(to bottom, var(--darkMode), var(--darkMode), var(--darkShade)) !important;
    outline: none !important;
    color: var(--readable);
    border: inherit;
    font-size: 20px;
    transition: 1s;
}

.see-review button:hover{
    border-bottom: 1px solid var(--blueText) !important;
}

.see-review button:focus,.see-review button:active{
    outline: none !important;
    box-shadow: none;
    color: var(--readable);
 }

.see-review-item{
    background: linear-gradient(to bottom, var(--darkMode), var(--darkMode), var(--darkShade));
    border-radius: 20%;
    border: 1px solid var(--darkMode);
    color: var(--readable);
    font-size: 20px;
}

@media only screen and (max-width: 768px){

    #bookDivW{
        margin: 50px;
    }

    .allBooksWriting{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .booksImagesW{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
    }

    .booksImagesW div{
        margin-bottom: 50px;
    }

    .firstBookImg{
        width: 100%;
    }

    .divBuyBookBtn {
        width: 100% !important;
        display: flex;
        justify-content: center;
    }

    #see-review{
        margin: 50px 0 50px 0;
    }

    .see-review button{
        width: 100% !important;
    }

    .see-review-item{
        width: 100% !important;
    }

    .accordion-button.collapsed::after {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='%23edeade'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M4 8L12 16L20 8' stroke='%23edeade' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }
      
    .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' stroke='%23edeade' transform='rotate(180)'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M20 16L12 8L4 16' stroke='%23edeade' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }
}