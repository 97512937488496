@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
  }
  
  :root{
    --mainColor: #e2e5ea;
    --mainColorLight: #5e6d7d;
    --secondaryColor: #E3E3E3; 
    --whiteColor: #FFFFFF;
    --offWhite: #F9F6EE;
    --lightGrey: #e6e7e7;
    --darkText: #454749;
    --darkMode: #0A0A0A;
    --darkShade: #181818;
    --darkShadeTwo: #121212;
    --blueText: #2b5a8d;
    --readable: #EDEADE;
  }

  .reviewTitle{
    margin: 0 50px 0 50px;
}

#divReview{
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(50deg, var(--darkShade), var(--darkShadeTwo));
    margin: 50px 50px 150px 50px;
    padding: 20px;
}

#reviewBorder{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
/* Frames on Grid */
.reviewText{
    box-shadow: 3px 3px var(--darkShade), -5px 0 2px black;
    padding: 10px;
    margin: 10px;
    width: 300px;
    color: var(--readable);
    font-size: 24px;
    transition: 1s;
    display: flex;
    flex-direction: column;
}

.reviewText:hover{
    box-shadow: 5px 5px var(--darkShade), -3px 0 5px var(--darkText);
}

.reviewText .pdf-text{
    color: var(--readable);
    font-size: 24px;
}

.reviewText a{
    text-decoration: none;
}

.reviewText span{
    display: flex;
    justify-content: end;
    align-items: flex-end;
    font-size: 15px;
    color: var(--mainColorLight);
}

/* Link with image on hover */

.reviewLink{
    box-sizing: border-box;
    overflow: hidden;
}

.pdf-img{
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: 1s ease-in-out;
}

.pdf-img:hover span{
    visibility: hidden;
}

/* Button download pdf */
.btn-pdf{
    margin-top: 15px;
    background: transparent;
    color: var(--offWhite);
    font-size: 15px;
    border: none;
    transition: 50ms;
}

.btn-pdf:disabled{
    color: var(--darkText);
}

.btn-pdf:disabled:hover{
    border: none;
    border-top: none;
    margin-top: 15px;
}

.btn-pdf:hover{
    border: none;
    border-top: 1px solid var(--whiteColor);
    margin-top: 14px;
}

.pdf{
    display: flex;
    justify-content: left;
}

.small-screen-img{
    display: none;
}

@media only screen and (max-width: 768px){

    .reviewLink:hover .pdf-text{
        color: var(--readable);
        text-decoration: underline;
    }
    /* REMOVING ALL THE BIG SCREEN BACKGROUND IMAGE */
    
    /* END REMOVING BG IMAGE */

    #divReview{
        margin: 50px 0px 50px 0px;
    }

    .reviewText{
        height: 100vh;
        width: 100%;
    }

    .small-screen-img{
        display: block;
        width: 100%;
        height: 50vh;
    }

    .pdf-text:hover{
        text-decoration: underline;
    }
}