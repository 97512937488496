@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');



  :root{
    --mainColor: #e2e5ea;
    --mainColorLight: #5e6d7d;
    --secondaryColor: #E3E3E3; 
    --whiteColor: #FFFFFF;
    --darkText: #454749;
    --darkMode: #0A0A0A;
    --darkShade: #181818; 
    --lightGrey: #e6e7e7;
    --blueText: #2b5a8d;
    --readable: #EDEADE;
  }

footer{
    background: linear-gradient(45deg, var(--darkMode), var(--darkShade));
    padding: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-top: var(--darkShade) 1px solid;
}

.footerTop{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--readable);
    font-size: 22px;
    margin-bottom: 50px;
}

.footerBottom{
    display: flex;
    justify-content: space-between;
    margin: 0 20px 20px 20px;
}
/* icon facebook and twiiter */
.footerTop div a{
    text-decoration: none;
    color: inherit;
    font-size: 40px;
    transition: 1s;
}

footer .footerTop div a:hover{
    color: var(--mainColorLight);
    
}

/* lign between social icons */
hr{
    border: none;
    background-color: var(--lightGrey);
    width: 200px;
    height: 2px;
}
/* bottom elements  */
.copyright{
    font-size: 12px;
    color: var(--lightGrey);

}

.arrow{
    font-size: 25px;
    color: var(--readable);
}

.arrow a{
    text-decoration: none;
    color: inherit;
    transition: 1s;
}

.arrow a:hover{
    color: var(--mainColorLight);
}