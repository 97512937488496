@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
  }
  
  :root{
    --mainColor: #e2e5ea;
    --mainColorLight: #5e6d7d;
    --secondaryColor: #E3E3E3; 
    --whiteColor: #FFFFFF;
    --darkText: #454749;
    --darkMode: #0A0A0A;
    --darkShade: #181818; 
    --blueText: #2b5a8d;
  }
/* HEADER STYLING */
  h3{
    color: var(--whiteColor);
  }

  .headTitle{
    color: var(--whiteColor);
    font-weight: lighter;
  }
  
  .link{
    text-decoration: none;
  }

  header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    height: 80px;
    padding: 20px 20px 20px 20px;
    background: var(--darkMode);
    color: var(--whiteColor);
    box-shadow: 1px 2px 5px var(--darkShade);
    font-family: 'EB Garamond', serif;
  }
  
  nav a{
    margin: 0 2rem;
    color: var(--whiteColor);
    text-decoration: none;
    border-bottom: none;
  }
  
  nav a:hover{
    color: var(--whiteColor);
    border-bottom: 4px solid var(--darkText);
    margin-bottom: -4px
  }

  .active-link{
    color: var(--whiteColor);
    border-bottom: 4px solid var(--whiteColor);
    margin-bottom: -4px
  }
  
  header .nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--whiteColor);
    visibility: hidden;
    opacity: 0;
    font-size: 28px;
  }

/* media query screen HEADER */
@media only screen and (max-width: 1900px){
    header .nav-btn{
        visibility: visible;
        opacity: 1;
    }

    header nav{position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        background: linear-gradient(45deg, var(--darkMode), var(--darkMode), var(--darkText));
        transition: 1s;
        transform: translateY(-100vh);
        z-index: 10;
    }

    header .responsive_nav{
        transform: none;
    }

    nav .nav-close-btn{
        position: absolute;
        top: 32px;
        right: 32px;
    }

    nav a{
        font-size: 26px;
    }
}