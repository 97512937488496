@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
  }
  
  :root{
    --mainColor: #e2e5ea;
    --mainColorLight: #5e6d7d;
    --secondaryColor: #E3E3E3; 
    --whiteColor: #FFFFFF;
    --lightGrey: #e6e7e7;
    --darkText: #454749; 
    --blueText: #2b5a8d;
  }

.contactDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0 150px 0;
}

label{
    font-size: 24px;
    color: var(--blueText);
}

input,textarea{
    box-shadow: 1px 1px 2px var(--mainColorLight);
    margin: 20px 0 20px 0;
    padding: 2px 2px 2px 2px;
    border: none;
    color: var(--whiteColor);
    background-color: var(--darkText);
}

input:focus,textarea:focus{
    border: none;
    color: black;
    background-color: var(--lightGrey);
    font-weight: bold;
}

textarea::placeholder {
    font-family: 'Roboto', sans-serif;
    opacity: .5;
    color: var(--whiteColor);
    text-shadow: 1px 1px 1px var(--darkText);
}

.button{
    background-color: var(--whiteColor);
    color: var(--darkText);
    border: none;
    border-radius: 5px;
    font-weight: bold;
    padding: 10px 30px 10px 30px;
    transition: background-color 1s ease-in-out;
}

.button:hover{
    background-color: var(--blueText);
    color: var(--whiteColor);
}

@media only screen and (max-width: 768px){
    form{
        width: 100%;
    }

    input,textarea{
        width: 100%;
    }
}