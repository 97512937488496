@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Roboto&display=swap');

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root{
    --mainColorLight: #5e6d7d;
    --whiteColor: #FFFFFF;
    --blueText: #2b5a8d;
    --darkText: #454749;
    --readable: #EDEADE;
  }

h1{
  color: var(--blueText);
}

#aboutText{
    text-align: left;
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    color: var(--readable);
    height: 100%;
    margin: 100px 50px 150px 50px;
    display: flex;
    flex-direction: column;
}

#bookDiv{
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  justify-content: space-around;
  font-size: 28px;
  font-weight: bolder;
  margin-top: 10px;
}

#bookText{
  width: 50%;
  color: var(--blueText);
  line-height: 50px;
  display: flex;
  align-items: center;
}

.bgLink{
  background-color: var(--darkText);
  color: var(--readable);
  padding: 0 5px 0 5px;
  text-decoration: none;
  transition: 1s;
}

.bgLink:hover{
  background-color: var(--whiteColor);
  color: var(--darkText);
}

.portraitAbout{
  box-shadow: 40px -16px var(--blueText);
  opacity: 1;
  width: 250px;
  height: 250px;
  background-image: url('../images/portrait-about.jpeg');
  background-repeat: no-repeat;
  background-position: right top -10px;
  animation-name: fadingAbout;
  animation-duration: 4s;
}

@keyframes fadingAbout{
  from{opacity: 0;}
  to{opacity: 1;}
}

@media only screen and (max-width: 768px){

  #bookDiv{
    display: flex;
    flex-direction: column !important;
    margin-top: 20px;
  }
  #bookText{
    font-size: 23px;
    font-weight: lighter;
  }
   .portraitAbout{
    width: 100%;
    margin: 5px;
   }
   .portraitAbout{
    box-shadow: 40px -16px var(--blueText);
    opacity: 1;
    width: 250px;
    height: 250px;
    background-image: url('../images/portrait-about.jpeg');
    background-repeat: no-repeat;
    background-position: right top -10px;}
}